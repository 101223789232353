import {
  Box,
  Container,
  Typography,
} from "@mui/material"
import Footer from "./footer";
import logo from "../assets/logo.svg"
import { backgroundBlue } from "../themes/standardTheme"

export default function Splash() {

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '100vw',
        height: '100vh',
        background: backgroundBlue
      }}
    >
      <Box
        sx={{
          marginTop: 10,
          minHeight: '90vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          background: backgroundBlue
        }}
      >
        <Box
          component="img"
          alt="zubu llc logo"
          src={logo}
          sx={{
            minWidth: '20rem',
            maxWidth: '30vw',
            background: backgroundBlue
          }} />
        <Typography
          component="div"
          variant="h4"
          color="#ffff"
          sx={{
            my: 10
          }}
        >
          <Box
            sx={{
              fontWeight: 'light',
              fontFamily: 'sans-serif'
            }}
          >
            (Coming soon.)
          </Box>
        </Typography>
        <Footer />
      </Box>
    </Container>
  )
}