import React from 'react';
import Splash from './components/splash';

function App() {
  return (
    <Splash></Splash>
  )
}

export default App;
