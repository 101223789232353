import {
  Box,
  Link,
  Typography,
  useTheme,
} from "@mui/material"
import { backgroundBlue } from "../themes/standardTheme"

export default function Footer() {
  const theme = useTheme()
  
  const copyrightText = 'Copyright © '
  
  return (
    <Box
      sx={{
        backgroundColor: backgroundBlue,
        flexDirection: 'column',
        width: '100',
        position: 'fixed',
        marginBottom: '0',
        bottom: '0',
        left: '0',
        right: '0',
      }}
    >
      <Typography
        variant="h6"
        color='#ffff'
        align="center"
        justifyContent='column-end'
      >
        {copyrightText}
        <Link
          sx={{ color: '#ffff' }}
          href="https://zubu.cloud">
          zubu llc
        </Link>{' '}
        {new Date().getFullYear()}.
      </Typography>
    </Box>
  )
}